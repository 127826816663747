import { Controller } from '@hotwired/stimulus';

/* eslint class-methods-use-this: "off" */

// Connects to data-controller="collapsible"
export default class extends Controller {
  static targets = [
    'toggleButton',
    'collapsibleSection',
  ];

  static values = {
    expanded: Boolean, // Easier name to think about than 'collapsed' (inverted meaning).
  }

  toggle() {
    if (this.expandedValue) {
      this.toggleButtonTarget.setAttribute('aria-expanded', false);
      this.collapseCollapsibleSection();
    } else {
      this.toggleButtonTarget.setAttribute('aria-expanded', true);
      this.expandCollapsibleSection();
    }
  }

  expand() {
    if (this.expandedValue) { return; }

    this.toggleButtonTarget.setAttribute('aria-expanded', true);
    this.expandCollapsibleSection();
  }

  collapseCollapsibleSection() {
    this.collapsibleSectionTarget.dataset.state = 'closed';
    this.expandedValue = false;
  }

  expandCollapsibleSection() {
    this.collapsibleSectionTarget.dataset.state = 'open';
    this.expandedValue = true;
  }
}
