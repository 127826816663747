import Helpers from '../lib/helpers';

class CollapsibleSection {
  constructor({ selector }) {
    // Check for existence of selector
    this.selector = document.querySelectorAll(selector);
    if (!this.selector) { return null; }

    // Run if selector found on page
    CollapsibleSection.toggleCollapsibleSections();
  }

  static toggleCollapsibleSections() {
    const headings = document.querySelectorAll('[data-panel-collapsible-title]');

    Helpers.forEach(headings, (heading) => {
      const btn = heading.querySelector('[data-panel-collapsible-btn]');
      const target = heading.nextElementSibling;
      const parentPanel = heading.parentElement;

      const gallery = target.querySelector('[data-js="gallery-slides"]');

      let glideInstanceName;
      if (gallery && gallery.dataset.glideId) {
        glideInstanceName = `glide-${gallery.dataset.glideId}`;
      }

      btn.onclick = () => {
        // Toggle data-state on heading container div
        heading.setAttribute('data-state', heading.getAttribute('data-state') === 'open' ? 'closed' : 'open');
        // Toggle data-state on panel container div
        parentPanel.setAttribute('data-state', parentPanel.getAttribute('data-state') === 'open' ? 'closed' : 'open');

        // Toggle aria-expanded on heading
        const expanded = btn.getAttribute('aria-expanded') === 'true' || false;
        btn.setAttribute('aria-expanded', !expanded);
        target.hidden = expanded;

        // Now the glide slider is not hidden or display: none - it should
        // successfully show if we call .update():
        const glide = window[glideInstanceName];
        if (glide) {
          glide.update();
          glide.recalculateNavArrowPosition();
        }
      };
    });
  }
}

export default CollapsibleSection;
